<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="3">
        <date-filter store="c2c"></date-filter>
      </v-col>
      <v-col cols="3">
        <site-group-filter store="c2c" />
      </v-col>
      <v-col cols="6">
        <site-filter store="c2c" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

export default {
  name: "Filters",
  components: {
    DateFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
